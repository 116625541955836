import React from 'react'
import Ruler from '~components/Ruler'
import useMediaQuery from '~hooks/useMediaQuery'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const ImageWrapper = ({ image }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  return (
    <div className="our-process__image--wrapper" ref={componentRef}>
      <img className="our-process__image" src={image.image} alt={image.image_alt} />
    </div>
  )
}

const OurProcess = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-20%",
    threshold: 0
  })

  const isMobile = useMediaQuery('(max-width: 768px)')

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  const leftImages = data.images.slice(0, 4).map((image, index) =>
    <ImageWrapper image={image} key={`our-process-left-image-${index}`} />
  )

  const imageColumn = (images, side) => {
    return (
      <div className={`our-process__image__column our-process__image__column--${side}`}>
        {
          images.map((image, index) =>
            <ImageWrapper image={image} key={`our-process-right-column-${side}-image-${index}`} />
          )
        }
      </div>
    )
  }
  const rightImages = () => {
    const topImage = data.images[4]
    const leftColumnImages = data.images.slice(5, 7)
    const rightColumnImages = data.images.slice(7)
    const leftColumn = imageColumn(leftColumnImages, 'left')
    const rightColumn = imageColumn(rightColumnImages, 'right')

    return (
      <>
        <div className="our-process__image--column our-process__image--column--top">
          <ImageWrapper image={topImage} />
        </div>
        <div className="our-process__image--column our-process__image--column--bottom">
          {leftColumn}
          {rightColumn}
        </div>
      </>
    )
  }

  const content = () => {
    if (isMobile) {
      const images = data.images.slice(0, 5)
      return (
        <>
          <div className="our-process__body">{data.body_text}</div>
          <div className="our-process__images">
            {
              images.map((image, index) =>
                <ImageWrapper image={image} key={`process-mobile-image-${index}`} />
              )
            }
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="our-process__content__left">
            <div className="our-process__body">{data.body_text}</div>
            <div className="our-process__content__left__images">
              {leftImages}
            </div>
          </div>
          <div className="our-process__content__right">
            <div className="our-process__content__right__images">
              {rightImages()}
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <section className="our-process" ref={componentRef}>
      <div className="our-process__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="our-process__content">
          {content()}
        </div>
      </div>
    </section>
  )
}

export default OurProcess
