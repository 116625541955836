import React from 'react'
import Ruler from '~components/Ruler'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const OurPartners = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const rulerData = {
    rightText: {
      tag: 'a',
      text: data.button_text,
      url: data.button_url
    },
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  const logos = data.partner_logos.map((image, index) =>
    <div className="our-partners__logo--wrapper" key={`logo-${index}`} style={{ transitionDelay: `${(index + 1) * 0.05}s`}}>
      <img className="our-partners__logo" src={image.logo} alt={image.logo_alt} />
    </div>
  )

  return (
    <section className="our-partners" ref={componentRef}>
      <div className="our-partners__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="our-partners__logos">
          {logos}
        </div>
      </div>
    </section>
  )
}

export default OurPartners
