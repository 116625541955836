import React from 'react'

import Layout from '~global/Layout'
import HomePageWrap from '~pageWraps/HomePageWrap'

import data from '../content/home.yaml'
import latestData from '../content/latest.yaml'
import worksData from '../content/work.yaml'

const Home = () => {
  return (
    <Layout>
      <HomePageWrap data={data} feed={latestData.feed} works={worksData.works} />
    </Layout>
  )
}

export default Home