import React, { useEffect, useState, useRef } from 'react'
import useMediaQuery from '~hooks/useMediaQuery'
import classnames from 'classnames'

import Button from '~components/Button'

import './index.scss'

const HomeHero = ({ data, isPreview }) => {
  const containerEl = useRef(null);
  const [currentImage, setCurrentImage] = useState(0);
  const isMobile = useMediaQuery('(max-width: 768px)')

  const buttonData = {
    tag: 'link',
    text: data.button_text,
    url: data.button_url
  }

  useEffect(() => {
    if (containerEl.current) {
      const interval = window.setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % data.images.length);
      }, 5000);

      return () => {
        window.clearInterval(interval);
      };
    }
  }, [setCurrentImage, data.images.length])

  const titleClass = classnames('home-hero__title', {
    'is-light': data.images[currentImage].title_color === 'light',
  })

  return (
    <section className="home-hero" ref={containerEl}>
      <div className="home-hero__top">
        <div className="home-hero__title-wrap">
          <div className="home-hero__title-container">
            <h1 className={titleClass}>
              {data.title.map((line, index) => (
                <span className="home-hero__title__line" key={index}>
                  {line}{' '}
                </span>
              ))}
            </h1>
          </div>
        </div>
        <div className="home-hero__images">
          {data.images.map((image, index) => (
            <img className={classnames('home-hero__image', {'is-current': currentImage == index})}
              alt="" src={isMobile ? image.mobile_image : image.desktop_image} />
          ))}
        </div>
      </div>
      <div className="home-hero__bottom-block">
        <p className="home-hero__subtitle">{data.subtitle}</p>
        <Button data={buttonData} isPreview={isPreview} />
      </div>
    </section>
  )
}

export default HomeHero
