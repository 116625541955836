import React, { useEffect, useRef, useState } from 'react'
import Link from '~components/Link'
import useMediaQuery from '~hooks/useMediaQuery'
import useWorks from '~hooks/useWorks'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import Ruler from '~components/Ruler'
import Button from '~components/Button'

import './index.scss'

const OurWork = ({ data, works, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const isMobile = useMediaQuery('(max-width: 768px)')

  const rulerData = {
    rightText: {
      tag: 'link',
      text: data.button_text,
      url: data.button_url
    },
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  const studies = useWorks(works).slice(0, 6)

  const workContent = (work, isExternal, isPreview) => {
    if (isMobile) {
      return (
        <div className="our-work__work__content">
          <h2 className="our-work__work__title">{work.work_name}</h2>
          <Button
            data={{
              tag: isExternal ? 'a' : 'link',
              text: isExternal ? 'Visit ↗︎' : 'Explore',
              url: isExternal ? work.project_link : `/work/${work.work_id}`
            }}
            isPreview={isPreview}
          />
        </div>
      )
    }
    if (isExternal) {
      return (
        <a href={work.project_link} target="_blank" rel="noopener noreferrer">
          <div className="our-work__work__content">
              <h2 className="our-work__work__title">{work.work_name}</h2>
              <Button
                data={{
                  tag: 'button',
                  text: 'Visit ↗︎'
                }}
                isPreview={isPreview}
              />
          </div>
        </a>
      )
    }
    return (
      <Link to={`/work/${work.work_id}`}>
        <div className="our-work__work__content">
            <h2 className="our-work__work__title">{work.work_name}</h2>
            <Button data=
              {{
                tag: 'button',
                text: 'Explore'
              }}
              isPreview={isPreview}
            />
        </div>
      </Link>
    )
  }

  const workList = studies.map((study, index) => {
    const isExternal = study.type === 'external_work'
    return (
      <div className="our-work__work" key={`work-${index}`}>
        {isExternal ?
          <a href={study.project_link} target="_blank" rel="noopener noreferrer">
            <div className="our-work__work__image--wrapper">
             <img className="our-work__work__image" alt="" src={study.image_landscape} />
            </div>
          </a> :
          <Link to={`/work/${study.work_id}`} isPreview={isPreview}>
            <div className="our-work__work__image--wrapper">
             <img className="our-work__work__image" alt="" src={study.image_landscape} />
            </div>
          </Link>
        }
        {workContent(study, isExternal, isPreview)}
      </div>
  )})

  return (
    <section className="our-work" ref={componentRef}>
      <div className="our-work__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="our-work__works">
          {workList}
        </div>
      </div>
    </section>
  )
}

export const OurWorkPreview = ({ data, isPreview }) => {
  const rulerData = {
    rightText: {
      tag: 'link',
      text: data.button_text,
      url: data.button_url
    },
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  return (
    <section className="our-work">
      <div className="our-work__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div>The first five works will be displayed here</div>
      </div>
    </section>
  )
}

export default OurWork;
