import React from 'react'

import Ruler from '~components/Ruler'
import Card from '~components/Card'
import useMediaQuery from '~hooks/useMediaQuery'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const CardWrapper = ({ item, isPreview }) => {
  //@TODO: move IO code into Card component
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-20%",
    threshold: 0
  })

  const cardData = (card) => {
    const caption = card.caption.length > 50 ? card.caption.slice(0, 47).concat('...') : card.caption
    return (
      {
        ruler: {
          rightText: {
            tag: 'button',
            text: 'More',
            url: card.url,
            color: 'white'
          },
          leftText: {
            color: 'grey',
            text: card.tag,
            type: 'label',
          }
        },
        description: caption,
        image_url: card.image
      }
    )
  }

  return (
    <a className="the-latest__card--wrapper" href={item.url} target="_blank" rel="noopener noreferrer" ref={componentRef}>
      <Card data={cardData(item)} isPreview={isPreview} />
    </a>
  )
}

const TheLatest = ({ data, feed, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  const isDesktop = useMediaQuery('(min-width: 1025px)')

  const rulerData = {
    rightText: {
      tag: 'link',
      text: data.button_text,
      url: data.button_url
    },
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  let cards = isDesktop ? feed.slice(0, 4) : feed.slice(0, 3)

  return (
    <section className="the-latest" ref={componentRef}>
      <div className="the-latest__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="the-latest__grid">
          {
            cards.map((card, index) =>
              <CardWrapper item={card} key={`the-latest-card-${card.url}`} isPreview={isPreview} />
            )
          }
        </div>
      </div>
    </section>
  )
}

export default TheLatest
