import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'

import HomeHero from '~components/pages/home/Hero'
import OurWork, { OurWorkPreview } from '~components/pages/home/OurWork'
import WhatWeDo from '~components/pages/home/WhatWeDo'
import OurPartners from '~components/pages/home/OurPartners'
import OurProcess from '~components/pages/home/OurProcess'
import TheLatest from '~components/pages/home/TheLatest'

const HomePageWrap = ({ data, feed, works, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <HomeHero data={data.hero} isPreview={isPreview} />
      { !isPreview && <OurWork data={data.our_work} works={works} isPreview={isPreview} /> }
      { isPreview && <OurWorkPreview data={data.our_work} isPreview={isPreview} /> }
      {/* <WhatWeDo data={data.what_we_do} isPreview={isPreview} /> */}
      <OurProcess data={data.our_process} isPreview={isPreview} />
      <OurPartners data={data.our_partners} isPreview={isPreview} />
      <TheLatest data={data.the_latest} feed={feed} isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

export default HomePageWrap
